<template>
  <div>VIEW</div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoicesMixin from "@/modules/invoices/mixins/InvoicesMixin";
import { delay } from "lodash";
import { InvoiceModule } from "@/store/invoice";
import { Invoice } from "@planetadeleste/vue-mc-gw";

@Component
export default class InvoicesView extends Mixins(InvoicesMixin) {
  bDisplayForm = false;

  onRegisterEvents() {
    this.addEvent(`${this.sModelName}.after.fetch`, () => {
      this.obItem.set("in_view", true);
      this.obItem.sync();
      InvoiceModule.setInvoice(this.obItem.attributes);

      this.showFormDelayed();
    });
  }

  onBeforeDestroy() {
    InvoiceModule.setInvoice(new Invoice());
    InvoiceModule.setTaxesAmount([]);
  }

  showFormDelayed(): void {
    delay(() => {
      this.bDisplayForm = true;
    }, 300);
  }
}
</script>
