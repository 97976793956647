import { Component, Mixins, Prop } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import type { InvoicePosition } from "@planetadeleste/vue-mc-gw";
import { Invoice, InvoiceCollection } from "@planetadeleste/vue-mc-gw";
import { AppModule } from "@/store/app";
import { InvoiceModule } from "@/store/invoice";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<Invoice, InvoiceCollection>
>;

@Component
export default class InvoicesMixin extends Mixins(TypedModelMixin) {
  @Prop(Boolean) readonly hideMovementTypeSelect!: boolean;

  obCollection = new InvoiceCollection();
  obModelClass = Invoice;
  obCollectionClass = InvoiceCollection;
  sRoutePath = "/invoices";
  forceSave = true;

  get positions(): InvoicePosition[] {
    return InvoiceModule.positions;
  }

  get discounts() {
    return InvoiceModule.discounts;
  }

  get inView() {
    return InvoiceModule.invoice.get("in_view", false);
  }

  get canSign() {
    return (
      this.inView || (!InvoiceModule.invoice.is_signed && InvoiceModule.canSign)
    );
  }

  get canIssue() {
    const obCompany = AppModule.company;

    return (
      this.canSign &&
      !!obCompany &&
      !!obCompany.ucfe_code &&
      !!obCompany.ucfe_password &&
      !!obCompany.ucfe_terminal
    );
  }

  created() {
    this.onCreated();
  }

  async cancel() {
    return Promise.resolve();
  }
}
